<!--  -->
<template>
    <div class="">
        <el-table
            :data="data"
            height="250"
            border
            style="margin-top: 10px; width: 100%"
            :header-cell-style="{ 'text-align': 'center' }"
        >
            <el-table-column prop="name" label="服务名称"></el-table-column>
            <el-table-column prop="price" label="服务价格"></el-table-column>
            <el-table-column prop="profile" label="服务简介"></el-table-column>

            <el-table-column fixed="right" label="操作" width="100">
                <template slot-scope="scope">
                    <el-button
                        @click="handleClickEdit(scope.row, scope.$index)"
                        type="text"
                        size="small"
                    >
                        编辑
                    </el-button>
                    <el-button
                        @click="handleClickdeleterecruitment(scope.$index)"
                        type="text"
                        size="small"
                        style="color: red"
                    >
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import bus from "@/utils/bus.js";
export default {
    props: ["data"],
    data() {
        return {};
    },
    methods: {
        handleClickdeleterecruitment(index) {
            const param = { type: 2, index };
            this.$emit("handleDelete", param);
        },
        handleClickEdit(item, index) {
            const param = { item, index };
            bus.$emit("editPriceService", param);
            this.$emit("handleVisible", 2);
        },
    },
};
</script>
