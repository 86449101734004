<template>
    <div v-if="list && list.length > 0">
        <span style="padding-bottom: 10px">
            <el-row :gutter="10">
                <el-col v-for="(item, index) in list" :key="index" :span="8">
                    <el-card style="width: 100%">
                        <div slot="header">
                            <el-row type="flex" justify="space-between">
                                <div style="font-weight: bold">
                                    {{ item.name }}
                                </div>
                                <div style="display: flex">
                                    <p
                                        class="edit"
                                        @click="handleClickEdit(item, index)"
                                    >
                                        编辑
                                    </p>
                                    <p
                                        class="delete"
                                        @click="handleClickDelete(index)"
                                    >
                                        删除
                                    </p>
                                </div>
                            </el-row>
                        </div>
                        <div>职位：{{ item.position }}</div>
                        <div>电话：{{ item.phone }}</div>
                        <div>邮箱：{{ item.email }}</div>
                    </el-card>
                </el-col>
            </el-row>
        </span>
    </div>
</template>

<script>
import bus from "@/utils/bus.js";
export default {
    props: ["list"],

    methods: {
        handleClickDelete(index) {
            const param = { type: 3, index };
            this.$emit("handleDelete", param);
        },
        handleClickEdit(item, index) {
            const param = { item, index };
            bus.$emit("editConcat", param);
            this.$emit("handleVisible", 3);
        },
    },
};
</script>
<style scoped>
.delete {
    color: red;
    cursor: pointer;
    text-align: center;
    margin: 0;
}
.edit {
    cursor: pointer;
    text-align: center;
    margin: 0;
    margin-right: 5px;
}
</style>
