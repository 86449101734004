<template>
    <div>
        <el-card class="box-card">
            <div class="main_title">
                {{ mainTitle }}
            </div>
            <div class="company_content">
                <ad-title title="供应商logo："></ad-title>
                <el-upload
                    class="avatar-uploader"
                    :action="actionUrl"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    :headers="uploadHeaders"
                >
                    <img
                        v-if="transData.logoUrl"
                        :src="transData.logoUrl"
                        class="avatar"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>

                <ad-title title="供应商名称："></ad-title>
                <el-input
                    v-model="currentUpdateData.name"
                    placeholder="请输入内容"
                ></el-input>
                <!-- 暂时隐藏，勿删 -->
                <!-- <template v-if="this.id">
                    <ad-title title="供应商是否上架："></ad-title>
                    <el-select v-model="status" style="width: 100%">
                        <el-option label="上架" :value="1"></el-option>
                        <el-option label="下架" :value="0"></el-option>
                    </el-select>
                </template> -->

                <ad-title title="区域选择："></ad-title>
                <el-cascader
                    style="width: 100%"
                    size="large"
                    :options="options"
                    v-model="transData.areaSelect"
                    clearable
                ></el-cascader>

                <ad-title title="所在地："></ad-title>
                <el-select
                    style="width: 100%"
                    v-model="currentUpdateData.position"
                    placeholder="请选择"
                >
                    <el-option
                        v-for="item in whereoptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select>

                <ad-title title="供应商类型："></ad-title>
                <el-select
                    style="width: 100%"
                    v-model="currentUpdateData.supType"
                    placeholder="请选择(可多选)"
                    multiple
                    @change="changeSupType"
                >
                    <el-option
                        v-for="item in supplieroptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select>

                <!-- 货代 -->
                <div v-if="judgeHasValue(1)">
                    <span class="tosmall_title">优势业务：</span>
                    <el-checkbox-group
                        v-model="currentUpdateData.superiorityBusinessForwarder"
                    >
                        <el-checkbox
                            v-for="city in forwardersoptions"
                            :label="city"
                            :key="city"
                        >
                            {{ city }}
                        </el-checkbox>
                    </el-checkbox-group>

                    <span class="tosmall_title">优势航线：</span>
                    <el-checkbox-group
                        v-model="currentUpdateData.superiorityCourseForwarder"
                    >
                        <el-checkbox
                            v-for="city in routeoptions"
                            :label="city"
                            :key="city"
                        >
                            {{ city }}
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
                <!-- 报关行(接口有点问题，沟通后特殊处理) -->
                <div v-if="judgeHasValue(3)">
                    <span class="tosmall_title">报关行：</span>
                    <el-checkbox-group
                        v-model="currentUpdateData.superiorityBusinessCustoms"
                    >
                        <el-checkbox
                            v-for="item in outinoptions"
                            :label="item"
                            :key="item"
                        >
                            {{ item }}
                        </el-checkbox>
                    </el-checkbox-group>
                </div>

                <ad-title title="详细地址："></ad-title>
                <el-input
                    v-model="currentUpdateData.address"
                    placeholder="请输入内容"
                ></el-input>

                <ad-title title="官网地址："></ad-title>
                <el-input
                    v-model="currentUpdateData.url"
                    placeholder="请输入内容"
                ></el-input>

                <ad-title title="邮箱"></ad-title>
                <el-input
                    v-model="currentUpdateData.email"
                    placeholder="请输入邮箱"
                    @blur="judgeInfo(`email`)"
                ></el-input>

                <ad-title title="电话"></ad-title>
                <el-input
                    v-model="currentUpdateData.phone"
                    placeholder="请输入电话"
                    @blur="judgeInfo(`phone`)"
                ></el-input>

                <ad-title title="简介："></ad-title>
                <markdown-editor
                    v-model="currentUpdateData.profile"
                ></markdown-editor>

                <ad-title title="业务优势："></ad-title>
                <markdown-editor
                    v-model="currentUpdateData.superiority"
                ></markdown-editor>

                <ad-title title="招聘广告信息："></ad-title>
                <el-button
                    size="small"
                    style="margin-top: 10px"
                    type="primary"
                    @click="recruitmentVisible = true"
                >
                    添加
                </el-button>
                <!-- 招聘广告弹框 -->
                <recruitment-dialog
                    @handleSubmit="handleDialog"
                    :visible="recruitmentVisible"
                />
                <!-- 招聘广告table -->
                <recruitment-table
                    :data="currentUpdateData.supJobList"
                    @handleDelete="handleDelete"
                    @handleVisible="handleVisible"
                />

                <br />

                <ad-title title="价格服务："></ad-title>
                <el-button
                    size="small"
                    type="primary"
                    @click="priceServiceVisible = true"
                >
                    添加
                </el-button>
                <!-- 价格服务弹框 -->
                <price-service-dialog
                    @handleSubmit="handleDialog"
                    :visible="priceServiceVisible"
                />
                <!-- 价格服务table -->
                <price-service-table
                    :data="currentUpdateData.supServices"
                    @handleDelete="handleDelete"
                    @handleVisible="handleVisible"
                />

                <ad-title title="轮播图库："></ad-title>
                <el-upload
                    class="upload-demo"
                    accept=".jpg,.png"
                    :action="actionUrl"
                    :show-file-list="true"
                    :file-list="transData.imageList"
                    :on-success="handleImageItemAdd"
                    :on-remove="handleImageItemDelete"
                    list-type="picture"
                    :headers="uploadHeaders"
                >
                    <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>

                <br />
                <ad-title title="宣传视频："></ad-title>
                <el-button v-if="videoIsEmpty" type="text" @click="toLookMp4">
                    预览
                </el-button>
                <el-upload
                    class="upload-demo"
                    accept=".mp4"
                    :before-upload="handleVideoLimit"
                    :action="actionUrl"
                    :show-file-list="true"
                    :headers="uploadHeaders"
                    :on-success="handleVideoItemAdd"
                    :on-remove="handleVideoItemDelete"
                    :file-list="transData.MP4List"
                    :auto-upload="!videoIsEmpty"
                >
                    <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
                <br />
                <ad-title title="联系人："></ad-title>
                <!-- 联系人弹窗 -->
                <concat-dialog
                    :visible="concatVisible"
                    @handleSubmit="handleDialog"
                />
                <!-- 联系人label -->
                <concat-label
                    :list="currentUpdateData.supContactList"
                    @handleDelete="handleDelete"
                    @handleVisible="handleVisible"
                ></concat-label>
                <br />
                <el-button
                    size="small"
                    type="primary"
                    @click="concatVisible = true"
                >
                    新增联系人
                </el-button>
            </div>
            <div style="text-align: center; margin-top: 4rem">
                <el-button size="small" @click="closeDailog()">取 消</el-button>
                <el-button
                    size="small"
                    type="primary"
                    @click="handleSave"
                    :loading="buttonLoading"
                >
                    确 定
                </el-button>
            </div>
        </el-card>
    </div>
</template>

<script>
import MarkdownEditor from "@/components/common/MarkdownEditor";
import AdTitle from "./component/AdTitle";
import { regionData, CodeToText, TextToCode } from "element-china-area-data";
import RecruitmentDialog from "./component/RecruitmentDialog";
import RecruitmentTable from "./component/RecruitmentTable";
import PriceServiceDialog from "./component/PriceServiceDialog";
import PriceServiceTable from "./component/PriceServiceTable";
import ConcatLabel from "./component/ConcatLabel";
import ConcatDialog from "./component/ConcatDialog";
import { addAdv, queryAdDetail, updateAdv } from "@/api/advertisement";
import { getUrlParams } from "@/utils";
import { isEmail, isPhone } from "@/utils/validate";
import { getToken } from "@/utils/auth";
export default {
    components: {
        MarkdownEditor,
        AdTitle,
        RecruitmentDialog,
        RecruitmentTable,
        PriceServiceDialog,
        PriceServiceTable,
        ConcatDialog,
        ConcatLabel,
    },
    data() {
        return {
            status: 1, // 是否上架
            recruitmentVisible: false, // 添加招聘信息弹窗
            priceServiceVisible: false, //添加价格弹窗
            concatVisible: false, //添加联系人弹窗
            buttonLoading: false,
            id: this.$route.query.id,
            options: regionData, //省市区数据
            // 用于upload展示的轮播图和视频
            MP4List: [],
            imageList: [],
            // 过度数据(后端不接收多余数据，所以过渡数据单独拿出来)
            transData: {
                logoUrl: "", // 供应商logo
                imageList: [], // 轮播图列表
                areaSelect: "", //选中的省市区数据
                MP4List: [], // 宣传视频列表
            },
            currentUpdateData: {
                supType: [], // 供应商类型
                position: "宁波", // 供应商位置
                name: "", // 供应商名称
                address: "", // 供应商地址
                url: "", // 官网地址
                profile: "", // 简介
                province: "", // 省
                area: "", //区
                city: "", //市
                email: "", // 邮箱
                phone: "", //电话
                superiority: "", // 业务优势
                supJobList: [], // 招聘列表
                supServices: [], // 服务列表
                supContactList: [], //联系人列表
                superiorityBusinessCarTeam: [], //车队优势业务
                superiorityBusinessCustoms: [], //报关行优势业务
                superiorityBusinessForwarder: [], //货代优势业务
                superiorityCourseCarTeam: [], //车队优势航线
                superiorityCourseCustoms: [], //报关行优势航线
                superiorityCourseForwarder: [], //	货代优势航线
            },
            whereoptions: [
                {
                    //所在地选项
                    label: "宁波",
                    value: "宁波",
                },
                {
                    label: "上海",
                    value: "上海",
                },
                {
                    label: "青岛",
                    value: "青岛",
                },
            ],
            supplieroptions: [
                //供应商选项
                {
                    label: "车队",
                    value: 2,
                },
                {
                    label: "货代",
                    value: 1,
                },
                {
                    label: "报关行",
                    value: 3,
                },
            ],
            forwardersoptions: [
                //货代选项
                "海运普柜",
                "海运拼箱",
                "特种柜",
                "冷柜",
                "国际铁路",
                "海铁联运",
                "海陆联运",
                "空运",
                "散货",
            ],
            routeoptions: [
                "欧洲",
                "地中海",
                "美加",
                "黑海",
                "中南美",
                "印巴",
                "港台",
                "日韩",
                "远东",
                "非洲",
                "大洋洲",
                "东南亚",
                "全航线",
            ],
            outinoptions: ["进口", "出口"],
            uploadHeaders: { Authorization: getToken() },
        };
    },
    computed: {
        mainTitle: function () {
            return this.$route.query.id ? "更新广告信息" : "新增广告信息";
        },
        // 媒体上传url
        actionUrl() {
            // return `${axiosFileEnum[process.env.VUE_APP_API_ENV]}upload`;
            return `${this.$base_url}/file/upload`;
        },
        videoIsEmpty() {
            return this.transData.MP4List && this.transData.MP4List.length > 0;
        },
    },
    mounted() {
        const { id } = getUrlParams();
        id && this.queryDetail(id);
    },
    methods: {
        changeSupType() {
            if (!this.judgeHasValue(1)) {
                this.currentUpdateData.superiorityBusinessForwarder = [];
                this.currentUpdateData.superiorityCourseForwarder = [];
            } else if (!this.judgeHasValue(3)) {
                this.currentUpdateData.superiorityBusinessCustoms = [];
            }
        },
        // 判断供应商中是否包含这个值
        judgeHasValue(value) {
            return this.currentUpdateData.supType.includes(value);
        },
        // logo上传
        // 上传成功(失败不用处理，已全局处理)
        handleAvatarSuccess(response) {
            this.transData.logoUrl = response.data.url;
        },
        // 信息判断
        judgeInfo(type) {
            const { email, phone } = this.currentUpdateData;
            if (type === "email") {
                if (!email) {
                    this.$message.warning("邮箱不能为空");
                } else if (!isEmail(email)) {
                    this.$message.warning("邮箱格式错误，请重新输入");
                    this.currentUpdateData.email = "";
                }
            } else {
                if (!phone) {
                    this.$message.warning("电话不能为空");
                } else if (!isPhone(phone)) {
                    this.$message.warning("电话格式错误,请重新输入");
                    this.currentUpdateData.phone = "";
                }
            }
        },
        // 处理信息提交
        handleDialog(data) {
            const { value, type, isEdit = false, index = 0 } = data;
            // 1:招聘  2:服务  3:联系人
            const infoType = {
                1: ["supJobList", "recruitmentVisible"],
                2: ["supServices", "priceServiceVisible"],
                3: ["supContactList", "concatVisible"],
            };

            const needDealItem = infoType[type][0];
            const needDealDialog = infoType[type][1];
            if (isEdit) {
                const copyVal = [...this.currentUpdateData[needDealItem]];
                copyVal[index] = value;
                this.currentUpdateData[needDealItem] = copyVal;
            } else {
                value && this.currentUpdateData[needDealItem].push(value);
            }
            this[needDealDialog] = false;
        },
        // 处理信息删除
        handleDelete(data) {
            const { type, index } = data;
            // 1:招聘  2:服务  3:联系人
            const infoType = {
                1: "supJobList",
                2: "supServices",
                3: "supContactList",
            };
            const needDealItem = infoType[type];

            this.currentUpdateData[needDealItem].splice(index, 1);
        },

        // 处理编辑弹框显示
        handleVisible(data) {
            // 1:招聘  2:服务  3:联系人
            const infoType = {
                1: "recruitmentVisible",
                2: "priceServiceVisible",
                3: "concatVisible",
            };
            this[infoType[data]] = true;
        },
        // 限制只能上传一个视频
        handleVideoLimit() {
            this.videoIsEmpty &&
                this.$message.warning(
                    "当前宣传视频仅支持上传一个，请删除之后再添加新的宣传视频",
                );
        },
        // 将轮播图存起来
        handleImageItemAdd(response) {
            this.transData.imageList.push({
                url: response.data.url,
                name: "轮播图片",
            });
        },
        // 轮播图删除
        handleImageItemDelete(file, fileList = []) {
            this.transData.imageList = fileList;
        },

        // 视频存起来
        handleVideoItemAdd(response) {
            this.transData.MP4List.push({
                url: response.data.url,
                name: "宣传视频",
            });
        },

        // 视频删除
        handleVideoItemDelete(file, fileList = []) {
            this.transData.MP4List = fileList;
        },

        // 关闭当前页面
        closeDailog() {
            window.close();
        },

        // 提交
        handleSave() {
            if (
                !this.currentUpdateData.supType ||
                this.currentUpdateData.supType.length === 0
            ) {
                this.$message.error("请至少选择一种供应商类型");
                return;
            }
            if (!this.currentUpdateData.name) {
                this.$message.error("供应商名称不能为空");
                return;
            }
            const { logoUrl, imageList, MP4List, areaSelect } = this.transData;
            // 1.头像，2.轮播图中的图片，3.视频资源
            const supResourceList = [];
            if (logoUrl) {
                supResourceList.push({
                    type: 1,
                    uri: logoUrl,
                });
            }
            if (imageList && imageList.length > 0) {
                imageList.forEach((item) => {
                    supResourceList.push({
                        type: 2,
                        uri: item.url,
                    });
                });
            }
            if (this.videoIsEmpty) {
                supResourceList.push({
                    type: 3,
                    uri: MP4List[0].url,
                });
            }
            const param = {
                id: this.id ? Number(this.id) : undefined,
                ...this.currentUpdateData,
                supResourceList,
                status: this.id ? this.status : undefined,
                // 单独处理报关行优势(字段要求是数组，这个存着是进口和出口)
                superiorityBusinessCustoms:
                    this.judgeHasValue(3) &&
                    this.currentUpdateData.superiorityBusinessCustoms
                        ? this.currentUpdateData.superiorityBusinessCustoms
                        : [],
                superiorityBusinessForwarder: this.judgeHasValue(1)
                    ? this.currentUpdateData.superiorityBusinessForwarder
                    : [],
                superiorityCourseForwarder: this.judgeHasValue(1)
                    ? this.currentUpdateData.superiorityCourseForwarder
                    : [],
                // 暂时用不到
                superiorityBusinessCarTeam: [],
                superiorityCourseCarTeam: [],
                superiorityCourseCustoms: [],
                // 省
                province: areaSelect[0] ? CodeToText[areaSelect[0]] : "",
                // 市
                city: areaSelect[1] ? CodeToText[areaSelect[1]] : "",
                // 区
                area: areaSelect[2] ? CodeToText[areaSelect[2]] : "",
            };
            this.buttonLoading = true;
            this.id
                ? updateAdv(param)
                      .then(() => {
                          this.$message.success("更新成功");
                          //   setTimeout(() => {
                          //       window.close();
                          //   }, 1000);
                      })
                      .finally(() => {
                          this.buttonLoading = false;
                      })
                : addAdv(param)
                      .then(() => {
                          this.$message.success("添加成功");
                          //   setTimeout(() => {
                          //       window.close();
                          //   }, 1000);
                      })
                      .finally(() => {
                          this.buttonLoading = false;
                      });
        },

        // 请求详细数据
        queryDetail(id) {
            queryAdDetail(id).then(({ data }) => {
                this.currentUpdateData = {
                    ...data.data,
                    superiorityBusinessCustoms: data.data
                        .superiorityBusinessCustoms
                        ? data.data.superiorityBusinessCustoms
                        : [],
                    superiorityBusinessForwarder:
                        data.data.superiorityBusinessForwarder ?? [],
                    superiorityCourseForwarder:
                        data.data.superiorityCourseForwarder ?? [],
                };
                // 赋值
                // 1.头像，2.轮播图中的图片，3.视频资源
                this.currentUpdateData.supResourceList &&
                    this.currentUpdateData.supResourceList.forEach((item) => {
                        if (item.type === 1) {
                            this.transData.logoUrl = item.uri;
                        } else if (item.type === 2) {
                            this.transData.imageList.push({
                                url: item.uri,
                                name: "轮播图片",
                            });
                        } else if (item.type === 3) {
                            this.transData.MP4List = [
                                { url: item.uri, name: "宣传视频" },
                            ];
                        }
                    });
                this.transData.areaSelect = [
                    TextToCode[data.data.province].code,
                    TextToCode[data.data.province][data.data.city].code,
                    TextToCode[data.data.province][data.data.city][
                        data.data.area
                    ].code,
                ];
            });
        },

        toLookMp4() {
            //预览视频
            window.open(this.transData.MP4List[0], "_blank");
        },
    },
};
</script>
<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}
.avatar {
    width: 100px;
    height: 100px;
    display: block;
}
</style>
<style lang="stylus" scoped>
.box-card {
    margin: 1rem auto;
    padding-bottom: 2rem;
    overflow: hidden;
    width: 900px;
}

.main_title {
    text-align: center;
    font-weight: bold;
    font-size: 25px;
}

.company_content {
    margin: 1rem auto;
    width: 800px;
    &_body{
        text-align: center;
        margin-right: 20px;
    }
}

.small_content {
    margin: 10px 20px;
    color: grey;
}

.people_div {
    display: inline-block;
    margin: 2rem;
    box-shadow: 0 5px 15px -5px rgba(0, 0, 0, .5);
    background: #dcdcdc;
    font-size: 15px;
}

.tosmall_title,
.small_title {
    font-weight: bold;
}

.people_span {
    display: block;
    margin: 10px 15px 10px 20px;
}

.tosmall_title {
    display: inline-block;
    line-height: 30px;
}

.addpeople_span,
    {
    display: block;
    line-height: 30px;
}

.select_div {
    margin: 20px 0 10px 0;
    width: 140px;
}
</style>
