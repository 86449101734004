<!--  -->
<template>
    <div class="tosmall_title">
        {{ title }}
    </div>
</template>

<script>
export default {
    props: ["title"],
};
</script>
