<template>
    <div>
        <el-dialog
            :title="title"
            width="30%"
            append-to-body
            :visible.sync="visible"
            :close-on-click-modal="false"
            center
        >
            <el-form :model="concat">
                <el-form-item label="姓名" :label-width="formLabelWidth">
                    <el-input
                        placeholder="请输入姓名"
                        v-model="concat.name"
                    ></el-input>
                </el-form-item>
                <el-form-item label="邮箱" :label-width="formLabelWidth">
                    <el-input
                        placeholder="请输入邮箱"
                        v-model="concat.email"
                    ></el-input>
                </el-form-item>
                <el-form-item label="电话" :label-width="formLabelWidth">
                    <el-input
                        placeholder="请输入联系方式"
                        v-model="concat.phone"
                    ></el-input>
                </el-form-item>
                <el-form-item label="职位" :label-width="formLabelWidth">
                    <el-input
                        placeholder="请输入职位"
                        v-model="concat.position"
                    ></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleSubmit(`cacel`)">取 消</el-button>
                <el-button type="primary" @click="handleSubmit(`submit`)">
                    确 定
                </el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import bus from "@/utils/bus.js";
export default {
    props: ["visible"],
    data() {
        return {
            concat: {
                email: "", // 邮箱
                name: "", // 姓名
                phone: "", // 电话
                position: "", //职位
            },
            formLabelWidth: "100px",
            copyConcat: {},
            empty: {
                email: "", // 邮箱
                name: "", // 姓名
                phone: "", // 电话
                position: "", //职位
            },
            title: "",
            index: null,
        };
    },
    mounted() {
        bus.$on("editConcat", (data) => {
            const { item, index = null } = data;
            this.concat = { ...item };
            this.index = index;
        });
        this.copyConcat = { ...this.concat };
        this.title = `${this.index === null ? "新增" : "编辑"}联系人信息`;
    },
    methods: {
        // 确定,取消提交
        handleSubmit(type) {
            const param = {
                value: type === "submit" ? this.concat : "",
                type: 3,
                isEdit: this.index !== null,
                index: this.index,
            };
            this.$emit("handleSubmit", param);
            // 赋值为空
            this.concat = this.empty;
            this.index = null;
        },
    },
};
</script>
