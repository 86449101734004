<!--  -->
<template>
    <div class="">
        <el-table
            :data="data"
            height="250"
            border
            style="margin-top: 10px; width: 100%"
            :header-cell-style="{ 'text-align': 'center' }"
        >
            <el-table-column prop="position" label="职位名称"></el-table-column>
            <el-table-column prop="numbers" label="招聘人数"></el-table-column>
            <el-table-column
                prop="requirement"
                label="招聘要求"
            ></el-table-column>
            <el-table-column prop="salary" label="薪资/月"></el-table-column>

            <el-table-column fixed="right" label="操作" width="100">
                <template slot-scope="scope">
                    <el-button
                        @click="handleClickEdit(scope.row, scope.$index)"
                        type="text"
                        size="small"
                    >
                        编辑
                    </el-button>
                    <el-button
                        @click="handleClickdeleterecruitment(scope.$index)"
                        type="text"
                        size="small"
                        style="color: red"
                    >
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import bus from "@/utils/bus.js";
export default {
    props: ["data"],
    data() {
        return {};
    },
    methods: {
        handleClickdeleterecruitment(index) {
            //删除当前价格服务数组
            const param = { type: 1, index };
            this.$emit("handleDelete", param);
        },
        handleClickEdit(item, index) {
            const param = { item, index };
            bus.$emit("editRecruitment", param);
            this.$emit("handleVisible", 1);
        },
    },
};
</script>
