<!--  -->
<template>
    <div>
        <el-dialog
            :title="title"
            width="30%"
            append-to-body
            :visible.sync="visible"
            :close-on-click-modal="false"
            center
        >
            <el-form :model="recruitment">
                <el-form-item label="招聘职位" :label-width="formLabelWidth">
                    <el-input
                        placeholder="请输入职位名称"
                        v-model="recruitment.position"
                    ></el-input>
                </el-form-item>
                <el-form-item label="招聘人数" :label-width="formLabelWidth">
                    <el-input
                        placeholder="请输入招聘人数"
                        v-model="recruitment.numbers"
                    ></el-input>
                </el-form-item>
                <el-form-item label="薪资" :label-width="formLabelWidth">
                    <el-input
                        placeholder="请输入薪资"
                        v-model="recruitment.salary"
                    ></el-input>
                </el-form-item>
                <el-form-item label="岗位描述" :label-width="formLabelWidth">
                    <el-input
                        placeholder="请简单输入招聘需求"
                        v-model="recruitment.requirement"
                    ></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleSubmit(`cacel`)">取 消</el-button>
                <el-button type="primary" @click="handleSubmit(`submit`)">
                    确 定
                </el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import bus from "@/utils/bus.js";
export default {
    props: ["visible"],
    data() {
        return {
            recruitment: {
                // 人数
                numbers: "",
                // 职位
                position: "",
                // 要求
                requirement: "",
                // 工资
                salary: "",
            },
            empty: {
                // 人数
                numbers: "",
                // 职位
                position: "",
                // 要求
                requirement: "",
                // 工资
                salary: "",
            },
            formLabelWidth: "100px",
            index: null,
            title: "",
        };
    },
    mounted() {
        bus.$on("editRecruitment", (data) => {
            const { item, index = null } = data;
            this.recruitment = { ...item };
            this.index = index;
        });
        this.title = `${this.index === null ? "新增" : "编辑"}招聘信息`;
    },
    methods: {
        // 确定,取消提交
        handleSubmit(type) {
            const param = {
                value: type === "submit" ? this.recruitment : "",
                type: 1,
                isEdit: this.index !== null,
                index: this.index,
            };
            this.$emit("handleSubmit", param);
            // 赋值为空
            this.recruitment = this.empty;
            this.index = null;
        },
    },
};
</script>
