<template>
    <div>
        <el-dialog
            :title="title"
            width="30%"
            append-to-body
            :visible.sync="visible"
            :close-on-click-modal="false"
            center
        >
            <el-form :model="priceService">
                <el-form-item label="服务名称" :label-width="formLabelWidth">
                    <el-input
                        placeholder="请输入服务名称"
                        v-model="priceService.name"
                    ></el-input>
                </el-form-item>
                <el-form-item label="服务价格" :label-width="formLabelWidth">
                    <el-input
                        placeholder="请输入服务价格"
                        v-model="priceService.price"
                    ></el-input>
                </el-form-item>
                <el-form-item label="服务简介" :label-width="formLabelWidth">
                    <el-input
                        placeholder="请输入服务简介"
                        v-model="priceService.profile"
                    ></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleSubmit(`cacel`)">取 消</el-button>
                <el-button type="primary" @click="handleSubmit(`submit`)">
                    确 定
                </el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import bus from "@/utils/bus.js";
export default {
    props: ["visible"],
    data() {
        return {
            priceService: {
                name: "", // 服务名称
                price: "", // 服务价格
                profile: "", // 服务简介
            },
            empty: {
                name: "", // 服务名称
                price: "", // 服务价格
                profile: "", // 服务简介
            },
            formLabelWidth: "100px",
            title: "",
            index: null,
        };
    },
    mounted() {
        bus.$on("editPriceService", (data) => {
            const { item, index = null } = data;
            this.priceService = { ...item };
            this.index = index;
        });

        this.title = `${this.index === null ? "新增" : "编辑"}服务信息`;
    },
    methods: {
        // 确定,取消提交
        handleSubmit(type) {
            const param = {
                value: type === "submit" ? this.priceService : "",
                type: 2,
                isEdit: this.index !== null,
                index: this.index,
            };
            this.$emit("handleSubmit", param);
            // 赋值为空
            this.priceService = this.empty;
            this.index = null;
        },
    },
};
</script>
